import Time from '@/utils/classes/Time';

export default {
  min: (val, maxDate, future = false) => {
    const now = Time.getRawDate().startOf('day');
    const momentVal = Time.getRawDate(val);
    const today = {
      year: now.year(),
      month: now.month() + 1,
      date: now.date(),
    };
    const start = {
      year: momentVal.year(),
      month: momentVal.month() + 1,
      date: momentVal.date(),
    };
    const shouldValidate = future ? momentVal >= now : momentVal <= now;
    if (shouldValidate) {
      const momentMaxDate = (maxDate && Time.getRawDate(maxDate)) || (!future && now);
      if (momentMaxDate) {
        const end = {
          year: momentMaxDate.year(),
          month: momentMaxDate.month() + 1,
          date: momentMaxDate.date(),
        };
        return (
          start.year < end.year ||
          (start.year === end.year && start.month < end.month) ||
          (start.year === end.year && start.month === end.month && start.date <= end.date)
        );
      }
      return (
        start.year > today.year ||
        (start.year === today.year &&
          (start.month > today.month || (start.month === today.month && start.date >= today.date)))
      );
    }
    return false;
  },
  max: (val, minDate, future = false) => {
    const now = Time.getRawDate().startOf('day');
    const momentVal = Time.getRawDate(val);
    const today = {
      year: now.year(),
      month: now.month() + 1,
      date: now.date(),
    };
    const start = {
      year: momentVal.year(),
      month: momentVal.month() + 1,
      date: momentVal.date(),
    };
    const shouldValidate = (future && momentVal >= now) || momentVal <= now;
    if (shouldValidate) {
      const momentMinDate = (minDate && Time.getRawDate(minDate)) || (future && now);
      if (momentMinDate) {
        const end = {
          year: momentMinDate.year(),
          month: momentMinDate.month() + 1,
          date: momentMinDate.date(),
        };
        return (
          start.year > end.year ||
          (start.year === end.year && start.month > end.month) ||
          (start.year === end.year && start.month === end.month && start.date >= end.date)
        );
      }
      return (
        start.year < today.year ||
        (start.year === today.year &&
          (start.month < today.month || (start.month === today.month && start.date <= today.date)))
      );
    }
    return false;
  },
  range(val, minDate, maxDate) {
    if (val && minDate && maxDate) {
      const momentVal = Time.getRawDate(val);
      const momentMinDate = Time.getRawDate(minDate);
      const momentMaxDate = Time.getRawDate(maxDate);
      return momentVal >= momentMinDate && momentVal <= momentMaxDate;
    }
    return false;
  },
};
