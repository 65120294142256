<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :attach="attach"
    :max-width="maxMenuWidth"
    class="ph-datepicker-menu"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" :style="customStyle" class="ph-datepicker">
        <v-text-field
          :label="label"
          :value="formattedValue"
          :rules="required ? ['required'] : []"
          :hide-details="hideDetails"
          :placeholder="placeholder"
          :full-width="fullWidth"
          autocomplete="new-password"
          class="ph-datepicker__text-field"
          :class="{ 'ph-datepicker__text-field--full-width': fullWidth }"
          :rounded="rounded"
          outlined
          readonly
          solo
        >
          <span>{{ value }}</span>
          <template v-slot:append>
            <SvgIcon icon="calendar" class="ph-datepicker__icon"></SvgIcon>
          </template>
        </v-text-field>
      </div>
    </template>
    <v-date-picker @input="handleInput" :allowed-dates="allowedDates" :value="value" no-title>
      <v-spacer />
      <v-btn
        @click="
          open = false;
          handleInput('');
        "
        color="primary"
        text
      >
        Cancel
      </v-btn>
      <v-btn @click="open = false" color="primary" text>
        Ok
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { dateTimePickerProperties } from '@/mixins';
import dateValidator from '@/utils/helpers/datetime/dateValidator';
import Time from '@/utils/classes/Time';

export default {
  name: 'PhDatepicker',
  mixins: [dateTimePickerProperties],
  props: {
    fullWidth: Boolean,
    allowAllDates: Boolean,
    allowedDatesFunc: Function,
    future: Boolean,
  },
  computed: {
    formattedValue() {
      if (this.value) {
        try {
          return Time.getFormattedDate(this.value, 'YYYY, MMM DD');
        } catch (err) {
          console.error(err);
        }
      }
      return this.value;
    },
  },
  methods: {
    allowedDates(value) {
      if (this.allowAllDates) {
        return true;
      }
      if (typeof this.allowedDatesFunc === 'function') {
        return this.allowedDatesFunc(value);
      }
      if (this.minDate && !this.maxDate) {
        return this.maxEndDate(value);
      }
      if (this.maxDate && !this.minDate) {
        return this.minStartDate(value);
      }
      if (this.minDate && this.maxDate) {
        return this.allowedDatesInRange(value);
      }
      if (this.future) {
        return dateValidator.min(value, null, this.future);
      }
      return dateValidator.min(value, null);
    },
    allowedDatesInRange(val) {
      return dateValidator.range(val, this.minDate, this.maxDate);
    },
    minStartDate(val) {
      return dateValidator.min(val, this.maxDate);
    },
    maxEndDate(val) {
      return dateValidator.max(val, this.minDate);
    },
  },
};
</script>

<style lang="scss">
.ph-datepicker-menu {
  .v-picker {
    .v-picker__body {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
.ph-datepicker {
  display: inline-block;
  min-width: 165px;
  .ph-datepicker__icon {
    svg {
      path {
        fill: var(--v-accent-base);
      }
    }
  }
  .ph-datepicker__text-field {
    &--full-width {
      width: 100%;
    }
    &.v-text-field {
      &--solo {
        &:not(.v-text-field--solo-flat) {
          & > .v-input__control {
            & > .v-input__slot {
              box-shadow: none;
              padding: 0 16px;
              .v-input__prepend-inner {
                padding-right: 16px;
              }
              .v-text-field__slot {
                font-size: 1rem;
              }
            }
          }
          &.v-input--is-dirty {
            & > .v-input__control {
              & > .v-input__slot {
                .v-text-field__slot {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
      &.theme--light {
        &:not(.v-input--is-focused) {
          &.v-text-field--rounded {
            & > .v-input__control {
              & > .v-input__slot {
                background: $background-lightest;
                & > fieldset {
                  border-color: $background-lightest;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
